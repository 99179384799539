<template>
<div class="info_blok block text-black">
  <div>Informační zpráva <span @click="removeMsg"><i class="fas fa-times"></i></span></div>
  <hr/>
  <InfoBlockMsg  v-for="(msg, index) in $store.state.listMsg" :key="index" v-bind:msg="msg.msg" v-bind:msg-type="msg.msgType"></InfoBlockMsg>
</div>
</template>

<script>
import InfoBlockMsg from "@/components/infoBlock/InfoBlockMsg";
export default {
  name: "InfoBlock",
  components: {InfoBlockMsg},
  methods:{
    removeMsg(){
      this.$store.commit('removeMsg')
    }
  }
}
</script>

<style scoped>
.info_blok {
  position: fixed;
  top: 10px;
  left: 10px;
  width: 320px;
  font-size: small;
}
</style>